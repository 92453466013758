import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWrapper } from "services/login";
import { fetchDownloadFITBTemplate } from "services/download";
import { postFITBUpload } from "services/upload";
export const downloadFITBTemplate = createAsyncThunk(
  "fitbBatchUpload/downloadFitbTemplate",
  async () => {
    try {
      const res = await fetchWrapper(fetchDownloadFITBTemplate);
      return res;
    } catch (error) {
      throw new Error(error?.message || "Download FITB template failed");
    }
  }
);
export const uploadFitbBatchUpload = createAsyncThunk(
  "fitbBatchUpload/postFitbUpload",
  async data => {
    try {
      const res = await fetchWrapper(postFITBUpload, data);
      return res;
    } catch (error) {
      throw new Error(error?.message || "FITB batch upload failed");
    }
  }
);
const initialState = {
  download: {
    isLoading: false,
    error: null,
  },
  upload: {
    isLoading: false,
    error: null,
  },
};
const fitbBatchUploadSlice = createSlice({
  name: "fitbBatchUploadSlice",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: {
    [downloadFITBTemplate.pending]: state => {
      state.download.isLoading = true;
      state.download.error = null;
    },
    [downloadFITBTemplate.fulfilled]: state => {
      state.download.isLoading = false;
    },
    [downloadFITBTemplate.rejected]: (state, action) => {
      state.download.isLoading = false;
      state.download.error = action.error.message;
    },
    [uploadFitbBatchUpload.pending]: state => {
      state.upload.isLoading = true;
      state.upload.error = null;
    },
    [uploadFitbBatchUpload.fulfilled]: state => {
      state.upload.isLoading = false;
    },
    [uploadFitbBatchUpload.rejected]: (state, action) => {
      state.upload.isLoading = false;
      state.upload.error = action.error.message;
    },
  },
});
export const { resetState } = fitbBatchUploadSlice.actions;

const { reducer } = fitbBatchUploadSlice;
export default reducer;
