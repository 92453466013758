import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWrapper } from "services/login";
import { fetchUploadStatus } from "services/upload";
export const getUploadStatus = createAsyncThunk("status/upload", async () => {
  try {
    const res = await fetchWrapper(fetchUploadStatus);
    return res;
  } catch (error) {
    throw new Error(error?.message || "Get upload status failed");
  }
});
const initialState = {
  upload: {
    isLoading: true,
    isErr: null,
    data: null,
  },
};

const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {},
  extraReducers: {
    [getUploadStatus.pending]: state => {
      return {
        ...state,
        upload: initialState.upload,
      };
    },
    [getUploadStatus.fulfilled]: (state, action) => {
      state.upload.isLoading = false;
      state.upload.data = action.payload;
    },
    [getUploadStatus.rejected]: (state, action) => {
      state.upload.isLoading = false;
      state.upload.isErr = action.error.message;
    },
  },
});
const { reducer } = statusSlice;
export default reducer;
