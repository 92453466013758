import {
  checkLukeContentRole,
  checkContentAdminRole,
} from "helpers/roleChecker";

const portals = [
  {
    id: 1,
    name: "LukeContentQA",
    roleChecker: checkLukeContentRole,
  },
  {
    id: 2,
    name: "ContentAdmin",
    roleChecker: checkContentAdminRole,
  },
];

export default portals;
