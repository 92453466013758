import handleResponse from "helpers/handleAPIResponse";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const fetchQuestions = ({ skillId, pageIndex, pageSize }) => {
  return fetch(
    `${baseUrl}/skill/${skillId}/question?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  )
    .then(handleResponse)
    .then(result => {
      if (!result.IsSuccessful) {
        throw new Error(result?.Message ?? "Get all questions failed");
      } else {
        return result.Result;
      }
    })
    .catch(err => {
      throw new Error(err?.message ?? "Get all questions failed");
    });
};

export const fetchQuestionById = ({ questionId }) => {
  return fetch(`${baseUrl}/question/${questionId}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(handleResponse)
    .then(result => {
      if (!result.IsSuccessful) {
        throw new Error(result?.Message?.Message);
      } else {
        return result.Result;
      }
    })
    .catch(err => {
      throw new Error(err?.message ?? "Get question by id failed");
    });
};

export const fetchQuestionQAById = ({ questionId }) => {
  return fetch(`${baseUrl}/question/${questionId}/QaVerification`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(handleResponse)
    .then(result => {
      if (!result.IsSuccessful) {
        throw new Error(result?.Message?.Message);
      } else {
        return result.result;
      }
    })
    .catch(err => {
      throw new Error(err?.message ?? "Get question QA by id failed");
    });
};

export const postQuestionQAById = params => {
  const { questionId } = params;
  return fetch(`${baseUrl}/question/${questionId}/QaVerification`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(handleResponse)
    .then(result => {
      if (!result.IsSuccessful) {
        throw new Error(result?.Message?.Message);
      } else {
        return result;
      }
    })
    .catch(err => {
      throw new Error(err?.message ?? "Update question QA by id failed");
    });
};

export const deleteQuestionQAById = ({ questionId }) => {
  return fetch(`${baseUrl}/question/${questionId}/QaVerification`, {
    method: "DELETE",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(handleResponse)
    .then(result => {
      if (!result.IsSuccessful) {
        throw new Error(result?.Message?.Message);
      } else {
        return result;
      }
    })
    .catch(err => {
      throw new Error(err?.message ?? "Delete question QA by id failed");
    });
};
