import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWrapper } from "services/login";
import { fetchDownloadMCQTemplate } from "services/download";
import { postMCQUpload } from "services/upload";
export const downloadMcqTemplate = createAsyncThunk(
  "mcqBatchUpload/downloadMcqTemplate",
  async () => {
    try {
      const res = await fetchWrapper(fetchDownloadMCQTemplate);
      return res;
    } catch (error) {
      throw new Error(error?.message || "Download MCQ template failed");
    }
  }
);
export const uploadMCQ = createAsyncThunk(
  "mcqBatchUpload/postMCQUpload",
  async data => {
    try {
      const res = await fetchWrapper(postMCQUpload, data);
      return res;
    } catch (error) {
      throw new Error(error?.message || "MCQ Batch upload failed");
    }
  }
);
const initialState = {
  download: {
    isLoading: false,
    error: null,
  },
  upload: {
    isLoading: false,
    error: null,
  },
};
const MCQBatchUploadSlice = createSlice({
  name: "MCQBatchUploadSlice",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: {
    [downloadMcqTemplate.pending]: state => {
      state.download.isLoading = true;
      state.download.error = null;
    },
    [downloadMcqTemplate.fulfilled]: state => {
      state.download.isLoading = false;
    },
    [downloadMcqTemplate.rejected]: (state, action) => {
      state.download.isLoading = false;
      state.download.error = action.error.message;
    },
    [uploadMCQ.pending]: state => {
      state.upload.isLoading = true;
      state.upload.error = null;
    },
    [uploadMCQ.fulfilled]: state => {
      state.upload.isLoading = false;
    },
    [uploadMCQ.rejected]: (state, action) => {
      state.upload.isLoading = false;
      state.upload.error = action.error.message;
    },
  },
});
export const { resetState } = MCQBatchUploadSlice.actions;

const { reducer } = MCQBatchUploadSlice;
export default reducer;
