import handleDownloadResponse from "helpers/handleDownloadResponse";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const fetchDownloadMCQTemplate = () => {
  return fetch(`${baseUrl}/Download/MCQ/Template`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(res => handleDownloadResponse(res, "MCQ.xlsx"))
    .catch(err => {
      throw new Error(err?.message ?? "Download MCQ template failed");
    });
};

export const fetchDownloadSkillDifficultyTemplate = () => {
  return fetch(`${baseUrl}/Download/SkillDifficulty/Template`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(res => handleDownloadResponse(res, "skillDifficultyTagging.xlsx"))
    .catch(err => {
      throw new Error(
        err?.message ?? "Download skill difficulty tagging template failed"
      );
    });
};
export const fetchDownloadFITBTemplate = () => {
  return fetch(`${baseUrl}/Download/FITB/Template`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(res => handleDownloadResponse(res, "FITB.xlsx"))
    .catch(err => {
      throw new Error(err?.message ?? "Download FITB template failed");
    });
};
