import handleResponse from "helpers/handleAPIResponse";

const baseUrl = process.env.REACT_APP_CONTENT_BASE_URL;

export const fetchSkillById = ({ skillId }) => {
  return fetch(`${baseUrl}/SIO/GetById/${skillId}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(handleResponse)
    .then(result => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch(err => {
      throw new Error(err?.message ?? "Get skill by id failed");
    });
};

export const fetchVariationsBySkillId = ({ skillId }) => {
  return fetch(`${baseUrl}/Variation/GetVariationBySIOId/${skillId}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(handleResponse)
    .then(result => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch(err => {
      throw new Error(err?.message ?? "Get variations by skill id failed");
    });
};

export const postParallelUpload = data => {
  const { skillId, variationId } = data.params;
  return fetch(
    `${baseUrl}/NewQuestion/skill/${skillId}/variation/${variationId}/parallelupload`,
    {
      method: "POST",
      body: data.formData,
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  )
    .then(handleResponse)
    .then(result => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch(err => {
      throw new Error(err?.message ?? "Upload parallel batch failed");
    });
};
