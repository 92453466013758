import handleResponse from "helpers/handleAPIResponse";
import store from "app/store";
const baseUrl = process.env.REACT_APP_BASE_URL;

export const postMCQUpload = data => {
  return fetch(`${baseUrl}/Upload/Users/${store.getState().login.userID}/MCQ`, {
    method: "POST",
    body: data.formData,
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(handleResponse)
    .then(result => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch(err => {
      throw new Error(err?.message ?? "Upload MCQ batch failed");
    });
};

export const postSkillDifficultyTaggingUpload = data => {
  return fetch(
    `${baseUrl}/Upload/Users/${store.getState().login.userID}/SkillDifficulty`,
    {
      method: "POST",
      body: data.formData,
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  )
    .then(handleResponse)
    .then(result => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch(err => {
      throw new Error(err?.message ?? "Upload skill difficulty tagging failed");
    });
};

export const postFITBUpload = data => {
  return fetch(
    `${baseUrl}/Upload/Users/${store.getState().login.userID}/FITB`,
    {
      method: "POST",
      body: data.formData,
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  )
    .then(handleResponse)
    .then(result => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch(err => {
      throw new Error(err?.message ?? "Upload FITB failed");
    });
};

export const fetchUploadStatus = () => {
  return fetch(
    `${baseUrl}/Upload/Users/${store.getState().login.userID}/Status`,
    {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  )
    .then(handleResponse)
    .then(result => {
      if (!result.IsSuccessful) {
        throw new Error(result?.Message ?? "Get upload status failed");
      } else {
        return result.Result;
      }
    })
    .catch(err => {
      throw new Error(err?.message ?? "Get Upload Status failed");
    });
};
