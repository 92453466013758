export const menus = [
  {
    id: 1,
    name: "search",
    url: "/",
    selection: ["/search", "/"],
  },
  // {
  //   id: 2,
  //   name: "summary",
  //   url: "/summary",
  //   selection: ["/summary"],
  // },
];

const subMenus = {
  name: "Question Upload",
  menuArr: [
    {
      id: 1,
      name: "batch-upload",
      url: "/batch-upload",
      selection: ["/batch-upload"],
    },
    {
      id: 2,
      name: "MCQ Batch-upload",
      url: "/mcq-batch-upload",
      selection: ["/mcq-batch-upload"],
    },
    {
      id: 3,
      name: "FITB Batch-upload",
      url: "/fitb-batch-upload",
      selection: ["/fitb-batch-upload"],
    },
  ],
};

const contentOperations = {
  name: "Content Operations",
  menuArr: [
    {
      id: 1,
      name: "Story Science",
      url: "/content/story-science",
      selection: ["/content/story-science"],
    },
    {
      id: 2,
      name: "KooClass",
      url: "/content/kooclass",
      selection: ["/content/kooclass"],
    },
  ],
};

const tagging = {
  name: "Tagging Upload",
  menuArr: [
    {
      id: 1,
      name: "Skill Difficulty Tagging",
      url: "/tagging/skill-difficulty",
      selection: ["/tagging/skill-difficulty"],
    },
  ],
};
const status = {
  name: "Status",
  menuArr: [
    {
      id: 1,
      name: "Upload Status",
      url: "/status/upload",
      selection: ["/status/upload"],
    },
  ],
};

export const contentRoleMenus = [subMenus, contentOperations, tagging, status];
