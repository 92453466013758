import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchSkillById,
  fetchVariationsBySkillId,
  postParallelUpload,
} from "services/batchUploads";
import { fetchWrapper } from "services/login";

export const getSkillById = createAsyncThunk(
  "batchUpload/getSkillById",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchSkillById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get skill by id failed");
    }
  }
);

export const getVariationsBySkillId = createAsyncThunk(
  "batchUpload/getVariationsBySkillId",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchVariationsBySkillId, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get variations by skill id failed");
    }
  }
);

export const uploadCSV = createAsyncThunk(
  "batchUpload/postParallelUpload",
  async (data) => {
    try {
      const res = await fetchWrapper(postParallelUpload, data);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Batch upload by ids failed");
    }
  }
);

const initialState = {
  skillLoading: false,
  skillError: [],
  currentSkill: {},
  variationsLoading: false,
  variationsError: [],
  currentVariations: [],
  uploadLoading: false,
  uploadError: [],
  uploadResult: [],
};

const batchUploadSlice = createSlice({
  name: "batchUploadSlice",
  initialState,
  reducers: {
    resetBatchUpload: (state) => {
      state.currentSkill = {};
      state.skillError = null;
      state.skillLoading = false;
      state.currentVariations = [];
      state.variationError = null;
      state.variationLoading = false;
      state.uploadResult = {};
      state.uploadLoading = false;
      state.uploadError = [];
    },
    setErrors: (state, { payload }) => {
      state.uploadError = payload;
    },
  },
  extraReducers: {
    [getSkillById.pending]: (state) => {
      state.currentSkill = {};
      state.skillError = null;
      state.skillLoading = true;
    },
    [getSkillById.fulfilled]: (state, { payload }) => {
      state.currentSkill = payload;
      state.skillError = null;
      state.skillLoading = false;
    },
    [getSkillById.rejected]: (state, action) => {
      state.currentSkill = {};
      state.skillLoading = false;
      state.skillError = action.error.message;
    },
    [getVariationsBySkillId.pending]: (state) => {
      state.variationsLoading = true;
      state.variationsError = null;
    },
    [getVariationsBySkillId.fulfilled]: (state, { payload }) => {
      state.currentVariations = payload;
      state.variationsError = null;
      state.variationsLoading = false;
    },
    [getVariationsBySkillId.rejected]: (state, action) => {
      state.variationsLoading = false;
      state.variationsError = action.error.message;
    },
    [uploadCSV.pending]: (state, action) => {
      state.uploadLoading = true;
      state.uploadError = [];
    },
    [uploadCSV.fulfilled]: (state, { payload }) => {
      state.uploadLoading = false;
      state.uploadResult = payload;
    },
    [uploadCSV.rejected]: (state, action) => {
      state.uploadLoading = false;
      state.uploadError = action.error.message;
    },
  },
});

export const { resetBatchUpload, setErrors } = batchUploadSlice.actions;

const { reducer } = batchUploadSlice;
export default reducer;
