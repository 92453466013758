import { useState } from "react";
import PropTypes from "prop-types";
import {
  AppShell,
  Avatar,
  Burger,
  Button,
  Center,
  Divider,
  Header,
  MediaQuery,
  MenuItem,
  Navbar,
  Space,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useHistory } from "react-router-dom";
import { Menu } from "@mantine/core";
import { useNotifications } from "@mantine/notifications";
import logout from "helpers/logout";
import { contentRoleMenus, menus } from "./constants";
import { NavTitle } from "./Layout.styles";
import {
  checkContentRole,
  checkLukeRole,
} from "components/Shared/RouteRequiresLogin";
import { Fragment } from "react";

const Layout = ({ children }) => {
  const history = useHistory();
  const [opened, setOpened] = useState(false);
  const [avatarOpened, setAvatarOpened] = useState(false);
  const theme = useMantineTheme();
  const [selectedMenu, setSelectedMenu] = useState(history.location.pathname);
  const notifications = useNotifications();
  const access_token = localStorage.getItem("access_token");

  // Event Handlers
  const logoutBtnClickHandler = () => {
    logout();
    notifications.showNotification({
      color: "green",
      title: "Log out successful!",
      message: "You have successfully logged out",
    });
  };
  const renderSubmenu = (menus) =>
    menus.map(({ id, name, url, selection }) => (
      <Center key={id}>
        <Button
          variant={selection.includes(selectedMenu) ? "light" : "subtle"}
          fullWidth
          size="md"
          radius={0}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            color: `${selection.includes(selectedMenu) ? "#248ae6" : "#000"}`,
          }}
          onClick={() => {
            setSelectedMenu(url);
            history.push(url);
          }}
        >
          {name.charAt(0).toUpperCase() + name.slice(1)}
        </Button>
      </Center>
    ));
  return (
    <AppShell
      navbarOffsetBreakpoint="sm"
      fixed
      navbar={
        <Navbar
          padding="md"
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 250, lg: 250 }}
          style={{ padding: 0 }}
        >
          {checkLukeRole(access_token) && (
            <>
              <Navbar.Section>
                <NavTitle>
                  <Center>Quality Assurance</Center>
                </NavTitle>
              </Navbar.Section>
              <Divider />
              <Navbar.Section>
                {menus.map(({ id, name, url, selection }) => (
                  <Center key={id}>
                    <Button
                      variant={
                        selection.includes(selectedMenu) ? "light" : "subtle"
                      }
                      fullWidth
                      size="md"
                      radius={0}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        color: `${
                          selection.includes(selectedMenu) ? "#248ae6" : "#000"
                        }`,
                      }}
                      onClick={() => {
                        setSelectedMenu(url);
                        history.push(url);
                      }}
                    >
                      {name.charAt(0).toUpperCase() + name.slice(1)}
                    </Button>
                  </Center>
                ))}
              </Navbar.Section>
              <Space h={40} />
            </>
          )}
          {checkContentRole(access_token) &&
            contentRoleMenus.map((menuItem) => (
              <Fragment key={menuItem.name}>
                <Navbar.Section>
                  <NavTitle>
                    <Center>{menuItem.name}</Center>
                  </NavTitle>
                </Navbar.Section>
                <Divider />
                <Navbar.Section>
                  {renderSubmenu(menuItem.menuArr)}
                </Navbar.Section>
                <Space h={40} />
              </Fragment>
            ))}
        </Navbar>
      }
      header={
        <Header height={70} padding="md">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
            <Text>L.U.K.E</Text>
            <Menu
              style={{ cursor: "pointer" }}
              position="bottom"
              placement="end"
              control={
                <Avatar
                  defaultValue={avatarOpened}
                  onChange={() => setAvatarOpened(!avatarOpened)}
                  radius="xl"
                  src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
                />
              }
            >
              <MenuItem onClick={logoutBtnClickHandler}>Logout</MenuItem>
            </Menu>
          </div>
        </Header>
      }
    >
      {children}
    </AppShell>
  );
};

Layout.propTypes = {
  children: PropTypes.array.isRequired,
};

export default Layout;
