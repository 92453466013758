import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { clone } from "ramda";
import {
  fetchCourses,
  fetchLessonsByTopicId,
  fetchTopicsByCourseId,
  patchLessonOrders,
  patchMoveLessonToTopic,
  patchTopicOrders,
} from "services/contentKooClass";
import { fetchWrapper } from "services/login";

export const getCourses = createAsyncThunk(
  "contentKooClass/getStoryLevels",
  async () => {
    try {
      const res = await fetchWrapper(fetchCourses, {});
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get courses failed");
    }
  }
);

export const getTopicsByCourseId = createAsyncThunk(
  "contentKooClass/getTopicsByCourseId",
  async params => {
    try {
      const res = await fetchWrapper(fetchTopicsByCourseId, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get topics by course id failed");
    }
  }
);

export const updateTopicOrders = createAsyncThunk(
  "contentKooClass/updateTopicOrders",
  async data => {
    try {
      const res = await fetchWrapper(patchTopicOrders, data);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Update topic orders failed");
    }
  }
);

export const getLessonsByTopicId = createAsyncThunk(
  "contentKooClass/getLesosnsByTopicId",
  async params => {
    try {
      const res = await fetchWrapper(fetchLessonsByTopicId, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get lessons by topic id failed");
    }
  }
);

export const updateLessonOrders = createAsyncThunk(
  "contentKooClass/updateLessonOrders",
  async data => {
    try {
      const res = await fetchWrapper(patchLessonOrders, data);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Update lesson orders failed");
    }
  }
);

export const updateLessonToTopic = createAsyncThunk(
  "contentKooClass/updateLessonToTopic",
  async data => {
    try {
      const res = await fetchWrapper(patchMoveLessonToTopic, data);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Update lesson to topic failed");
    }
  }
);

const initialState = {
  courses: {
    data: [],
    loading: false,
    errors: null,
  },
  currentCourse: null,
  topics: {
    data: [],
    loading: false,
    errors: null,
  },
  topicOrders: {
    loading: false,
    errors: null,
  },
  currentTopic: {},
  lessons: {
    data: [],
    loading: false,
    errors: null,
  },
  lessonOrders: {
    loading: false,
    errors: null,
  },
  currentLesson: {},
  selectedTopic: {
    data: null,
    loading: false,
    errors: null,
  },
};

const contentKooClassSlice = createSlice({
  name: "contentKooClassSlice",
  initialState,
  reducers: {
    setCourse: (state, { payload }) => {
      state.currentCourse = payload;
    },
    setTopic: (state, { payload }) => {
      state.currentTopic = payload;
    },
    setLesson: (state, { payload }) => {
      state.currentLesson = payload;
    },
    setSelectedTopic: (state, { payload }) => {
      state.selectedTopic.data = payload;
    },
    clearSelectedTopic: state => {
      return {
        ...state,
        selectedTopic: initialState.selectedTopic,
      };
    },
    clearTopics: state => {
      return {
        ...state,
        topics: initialState.topics,
      };
    },
  },
  extraReducers: {
    [getCourses.pending]: state => {
      state.courses.loading = true;
    },
    [getCourses.fulfilled]: (state, { payload }) => {
      state.courses.data = payload.map(({ id, name }) => ({
        value: id.toString(),
        label: name,
      }));
      state.courses.errors = null;
      state.courses.loading = false;
    },
    [getCourses.rejected]: (state, action) => {
      state.courses.loading = false;
      state.courses.errors = action.error.message;
    },
    [getTopicsByCourseId.pending]: state => {
      state.topics.loading = true;
    },
    [getTopicsByCourseId.fulfilled]: (state, { payload }) => {
      state.topics.data = payload.map(({ id, name, displayOrder }) => ({
        Id: id.toString(),
        Name: name,
        DisplayOrder: displayOrder,
      }));
      state.topics.errors = null;
      state.topics.loading = false;
    },
    [updateTopicOrders.pending]: state => {
      state.topicOrders.loading = true;
    },
    [updateTopicOrders.fulfilled]: (state, action) => {
      state.topicOrders.loading = false;
      state.topicOrders.errors = null;
      const { Updates } = action.meta.arg;
      const displayOrders = Updates.reduce((acc, { Id, DisplayOrder }) => {
        acc[Id] = DisplayOrder;
        return acc;
      }, {});
      state.topics.data = clone(state.topics.data)
        .map(topic => {
          return {
            ...topic,
            DisplayOrder: displayOrders[topic.Id],
          };
        })
        .sort((a, b) => a.DisplayOrder - b.DisplayOrder);
    },
    [updateTopicOrders.rejected]: (state, action) => {
      state.topicOrders.loading = false;
      state.topicOrders.errors = action.error.message;
    },
    [getTopicsByCourseId.rejected]: (state, action) => {
      state.topics.loading = false;
      state.topics.errors = action.error.message;
    },
    [getLessonsByTopicId.pending]: state => {
      state.lessons.loading = true;
    },
    [getLessonsByTopicId.fulfilled]: (state, { payload }) => {
      state.lessons.data = payload.map(({ id, name, displayOrder }) => ({
        Id: id.toString(),
        Name: name,
        DisplayOrder: displayOrder,
      }));
      state.lessons.errors = null;
      state.lessons.loading = false;
    },
    [getLessonsByTopicId.rejected]: (state, action) => {
      state.lessons.loading = false;
      state.lessons.errors = action.error.message;
    },
    [updateLessonOrders.pending]: state => {
      state.lessonOrders.loading = true;
    },
    [updateLessonOrders.fulfilled]: (state, action) => {
      state.lessonOrders.loading = false;
      state.lessonOrders.errors = null;
      const { Updates } = action.meta.arg;
      const displayOrders = Updates.reduce((acc, { Id, DisplayOrder }) => {
        acc[Id] = DisplayOrder;
        return acc;
      }, {});
      state.lessons.data = clone(state.lessons.data)
        .map(lesson => {
          return {
            ...lesson,
            DisplayOrder: displayOrders[lesson.Id],
          };
        })
        .sort((a, b) => a.DisplayOrder - b.DisplayOrder);
    },
    [updateLessonOrders.rejected]: (state, action) => {
      state.lessonOrders.loading = false;
      state.lessonOrders.errors = action.error.message;
    },
    [updateLessonToTopic.pending]: state => {
      state.selectedTopic.loading = true;
    },
    [updateLessonToTopic.fulfilled]: state => {
      const newLessons = state.lessons.data.filter(
        lesson => lesson.Id !== state.currentLesson.Id
      );
      return {
        ...state,
        lessons: {
          ...initialState.lessons,
          data: newLessons,
        },
        currentLesson: initialState.currentLesson,
        selectedTopic: initialState.selectedTopic,
      };
    },
    [updateLessonToTopic.rejected]: (state, action) => {
      state.selectedTopic.loading = false;
      state.selectedTopic.errors = action.error.message;
    },
  },
});

export const {
  setSubject,
  setCourse,
  setTopic,
  setLesson,
  setSelectedTopic,
  clearSelectedTopic,
  clearTopics,
} = contentKooClassSlice.actions;
const { reducer } = contentKooClassSlice;
export default reducer;
