import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchQuestions,
  fetchQuestionById,
  fetchQuestionQAById,
  postQuestionQAById,
  deleteQuestionQAById,
} from "services/questions";
import {
  initialQuestion,
  initialPaginateQuestions,
} from "constants/initialQuestion";
import { fetchWrapper } from "services/login";
import moment from "moment";

export const getQuestions = createAsyncThunk(
  "questions/getAll",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchQuestions, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get all questions failed");
    }
  }
);

export const getQuestionById = createAsyncThunk(
  "questions/getQuestionById",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchQuestionById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get question by id failed");
    }
  }
);

export const getQuestionQAById = createAsyncThunk(
  "questions/getQuestionQAById",
  async (params) => {
    try {
      const res = await fetchWrapper(fetchQuestionQAById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get question qa by id failed");
    }
  }
);

export const updateQuestionQAById = createAsyncThunk(
  "questions/updateQuestionQAById",
  async (params) => {
    try {
      const res = await fetchWrapper(postQuestionQAById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Update question qa by id failed");
    }
  }
);

export const removeQuestionQAById = createAsyncThunk(
  "questions/removeQuestionQAById",
  async (params) => {
    try {
      const res = await fetchWrapper(deleteQuestionQAById, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Remove question qa by id failed");
    }
  }
);

const initialState = {
  questions: initialPaginateQuestions.list,
  pageIndex: 0,
  pageSize: 0,
  totalCount: 0,
  questionsLoading: false,
  questionsError: null,
  isLoading: false,
  isError: null,
  currentQuestion: initialQuestion,
  currentQuestionQA: {},
};

const questionSlice = createSlice({
  name: "questionSlice",
  initialState,
  reducers: {
    resetQuestion: (state) => {
      state.currentQuestion = initialQuestion;
      state.questions = initialPaginateQuestions.list;
      state.pageIndex = initialPaginateQuestions.pageIndex;
      state.pageSize = initialPaginateQuestions.pageSize;
      state.totalCount = initialPaginateQuestions.totalCount;
    },
    selectQuestion: (state, { payload }) => {
      state.currentQuestion = payload;
    },
  },
  extraReducers: {
    [getQuestions.pending]: (state) => {
      state.questionsLoading = true;
      state.questionsError = null;
    },
    [getQuestions.fulfilled]: (state, { payload }) => {
      state.questionsLoading = false;
      state.questions = payload.List;
      state.pageIndex = payload.PageIndex;
      state.pageSize = payload.PageSize;
      state.totalCount = payload.TotalCount;
      state.questionsError = null;
    },
    [getQuestions.rejected]: (state, action) => {
      state.questionsLoading = false;
      state.questions = initialPaginateQuestions.list;
      state.pageIndex = initialPaginateQuestions.pageIndex;
      state.pageSize = initialPaginateQuestions.pageSize;
      state.totalCount = initialPaginateQuestions.totalCount;
      state.questionsError = action.error.message;
    },
    [getQuestionById.pending]: (state) => {
      state.questions = [];
      state.currentQuestion = initialQuestion;
      state.isLoading = true;
      state.isError = null;
    },
    [getQuestionById.fulfilled]: (state, { payload }) => {
      state.questions = [payload];
      state.currentQuestion = payload;
      state.isError = null;
      state.isLoading = false;
    },
    [getQuestionById.rejected]: (state, action) => {
      state.questions = [];
      state.currentQuestion = initialQuestion;
      state.isLoading = false;
      state.isError = action.error.message;
    },
    [getQuestionQAById.pending]: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    [getQuestionQAById.fulfilled]: (state, { payload }) => {
      state.currentQuestionQA = payload;
      state.isError = null;
      state.isLoading = false;
    },
    [getQuestionQAById.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    },
    [updateQuestionQAById.pending]: (state, action) => {
      state.isLoading = true;
      state.isError = null;
      // Update state to display UI as checked with QA status
      if (action.meta.arg.selectedQn?.id) {
        const newQuestions = state.questions.map((question) =>
          question.id === action.meta.arg.selectedQn.id
            ? {
                ...question,
                isVerified: true,
                lastVerifiedBy: action.meta.arg.username,
                lastVerifiedDateTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
              }
            : question
        );
        state.questions = newQuestions;
      }
    },
    [updateQuestionQAById.fulfilled]: (state) => {
      state.isError = null;
      state.isLoading = false;
    },
    [updateQuestionQAById.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
      // Update UI if api request was unsuccessful
      if (action.meta.arg.selectedQn?.id) {
        const newQuestions = state.questions.map((question) =>
          question.id === action.meta.arg.selectedQn.id
            ? {
                ...question,
                isVerified: false,
                lastVerifiedBy: null,
                lastVerifiedDateTime: null,
              }
            : question
        );
        state.questions = newQuestions;
      }
    },
  },
});

export const { resetQuestion, selectQuestion } = questionSlice.actions;

const { reducer } = questionSlice;
export default reducer;
