import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "@mantine/hooks";
import { useHistory } from "react-router-dom";
import { EnvelopeClosedIcon, LockClosedIcon } from "@radix-ui/react-icons";
import {
  TextInput,
  PasswordInput,
  Group,
  Button,
  Paper,
  Text,
  LoadingOverlay,
} from "@mantine/core";
import { useNotifications } from "@mantine/notifications";
import { getToken } from "store/login/loginSlice";
import {
  checkContentRole,
  checkQAContentRole,
} from "components/Shared/RouteRequiresLogin";

const Login = ({ noPadding }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const notifications = useNotifications();
  const { isLoading } = useSelector((state) => state.login);
  const [error, setError] = useState(null);

  const form = useForm({
    initialValues: {
      loginID: "",
      password: "",
    },

    validationRules: {
      loginID: (value) => /^.{6,}$/.test(value),
    },

    errorMessages: {
      loginID: "Invalid login ID",
      password: "Password should contain at least 6 characters",
    },
  });

  const errorMessages = {
    invalid_username_or_password: "Invalid username/password",
    invalid_roles: "Your account does not have the correct role",
  };

  const handleSubmit = async (values) => {
    setError(null);
    const loginID = values.loginID;
    const password = values.password;
    try {
      const res = await dispatch(
        getToken({
          username: loginID,
          password,
        })
      );
      if (res?.error) {
        throw new Error(res.error.message);
      }
      const accessToken = res?.payload.access_token;
      const refreshToken = res?.payload.refresh_token;
      const hasAccess = checkQAContentRole(accessToken);
      if (!hasAccess) {
        throw new Error("invalid_roles");
      }
      localStorage.setItem("access_token", accessToken);
      localStorage.setItem("refresh_token", refreshToken);

      if (!checkQAContentRole(accessToken) && checkContentRole(accessToken)) {
        history.replace("/batch-upload");
      } else {
        history.replace("/");
      }
      notifications.showNotification({
        color: "green",
        title: "Login successful!",
        message: "You have successfully logged in",
      });
    } catch (err) {
      notifications.showNotification({
        color: "red",
        title: "Login unsuccessful!",
        message: errorMessages[err?.message] ?? err.message,
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          <h2 style={{ color: "orange", marginBottom: 0 }}>KooBits</h2>{" "}
          <p style={{ marginBottom: 2, marginLeft: 5 }}>presents</p>
        </span>
        <h1 style={{ fontSize: "48px", marginTop: 0, marginBottom: 0 }}>
          L.U.K.E
        </h1>
      </div>
      <Paper
        padding={noPadding ? 0 : "lg"}
        shadow={null}
        style={{
          height: "300px",
          width: "500px",
        }}
      >
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <LoadingOverlay visible={isLoading} />
          <TextInput
            mt="md"
            required
            placeholder="Your login ID"
            label="Login ID"
            icon={<EnvelopeClosedIcon />}
            {...form.getInputProps("loginID")}
          />

          <PasswordInput
            mt="md"
            required
            placeholder="Password"
            label="Password"
            icon={<LockClosedIcon />}
            {...form.getInputProps("password")}
          />

          {error && (
            <Text color="red" size="sm" mt="sm">
              {error}
            </Text>
          )}

          <Group position="center" mt="xl">
            <Button color="orange" type="submit">
              Login
            </Button>
          </Group>
        </form>
      </Paper>
    </div>
  );
};

export default Login;
