import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { clone } from "ramda";
import {
  fetchStoryLevels,
  fetchStoriesByLevelId,
  patchStoryOrders,
} from "services/contentOperations";
import { fetchWrapper } from "services/login";

export const getStoryLevels = createAsyncThunk(
  "contentOperation/getStoryLevels",
  async () => {
    try {
      const res = await fetchWrapper(fetchStoryLevels, {});
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get skill by id failed");
    }
  }
);

export const getStoriesByLevelId = createAsyncThunk(
  "contentOperation/getStoriesByLevelId",
  async params => {
    try {
      const res = await fetchWrapper(fetchStoriesByLevelId, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Get variations by skill id failed");
    }
  }
);

export const updateStoryOrders = createAsyncThunk(
  "contentOperation/updateStoryOrders",
  async data => {
    try {
      const res = await fetchWrapper(patchStoryOrders, data);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? "Update story orders failed");
    }
  }
);

const initialState = {
  levels: {
    data: [],
    loading: false,
    errors: null,
  },
  currentLevel: null,
  stories: {
    data: [],
    loading: false,
    errors: null,
  },
  storyOrders: {
    loading: false,
    errors: null,
  },
};

const contentOperationsSlice = createSlice({
  name: "contentOperationsSlice",
  initialState,
  reducers: {
    setLevel: (state, { payload }) => {
      state.currentLevel = payload;
    },
  },
  extraReducers: {
    [getStoryLevels.pending]: state => {
      state.levels.loading = true;
    },
    [getStoryLevels.fulfilled]: (state, { payload }) => {
      state.levels.data = payload.map(({ LevelId, Wname }) => ({
        value: LevelId.toString(),
        label: Wname,
      }));
      state.levels.errors = null;
      state.levels.loading = false;
    },
    [getStoryLevels.rejected]: (state, action) => {
      state.levels.loading = false;
      state.levels.errors = action.error.message;
    },
    [getStoriesByLevelId.pending]: state => {
      state.stories.loading = true;
    },
    [getStoriesByLevelId.fulfilled]: (state, { payload }) => {
      state.stories.data = payload;
      state.stories.errors = null;
      state.stories.loading = false;
    },
    [getStoriesByLevelId.rejected]: (state, action) => {
      state.stories.loading = false;
      state.stories.errors = action.error.message;
    },
    [updateStoryOrders.pending]: state => {
      state.storyOrders.loading = true;
    },
    [updateStoryOrders.fulfilled]: (state, action) => {
      state.storyOrders.loading = false;
      state.storyOrders.errors = null;
      const { Updates } = action.meta.arg;
      const displayOrders = Updates.reduce((acc, { Id, DisplayOrder }) => {
        acc[Id] = DisplayOrder;
        return acc;
      }, {});
      state.stories.data = clone(state.stories.data)
        .map(story => {
          return {
            ...story,
            DisplayOrder: displayOrders[story.Id],
          };
        })
        .sort((a, b) => a.DisplayOrder - b.DisplayOrder);
    },
    [updateStoryOrders.rejected]: (state, action) => {
      state.storyOrders.loading = false;
      state.storyOrders.errors = action.error.message;
    },
  },
});

export const { setLevel } = contentOperationsSlice.actions;
const { reducer } = contentOperationsSlice;
export default reducer;
