import handleResponse from "helpers/handleAPIResponse";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const fetchStoryLevels = ({ subjectId = 2 }) => {
  return fetch(`${baseUrl}/Story/StoryWorlds?subjectId=${subjectId}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get story levels failed");
    });
};

export const fetchStoriesByLevelId = ({ levelId }) => {
  return fetch(`${baseUrl}/Story/StoryWorlds/${levelId}/StoryChapters`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get stories by level id failed");
    });
};

export const patchStoryOrders = (data) => {
  return fetch(`${baseUrl}/Story/StoryChapters/BulkUpdateDisplayOrder`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      "content-type": "application/json",
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Update stories order failed");
    });
};
