import fileDownload from "js-file-download";

const handleDownloadResponse = async (response, fileName) => {
  if (response.ok) {
    const data = await response.blob();
    return fileDownload(data, fileName);
  }
  throw new Error(response.statusText);
};

export default handleDownloadResponse;
