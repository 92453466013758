import React from "react";
import ReactDOM from "react-dom";
import GlobalStyle from "components/GlobalStyle";
import store from "app/store";
import { Provider } from "react-redux";
import { NotificationsProvider } from "@mantine/notifications";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <NotificationsProvider position="top-right">
        <GlobalStyle />
        <App />
      </NotificationsProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
