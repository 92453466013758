import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
// APPEND IMPORTS HERE
import statusReducer from "store/status/statusSlice";
import batchUploadReducer from "store/batchUpload/batchUploadSlice";
import questionReducer from "store/question/questionSlice";
import contentReducer from "store/contentOperations/contentOperationsSlice";
import contentKooClassReducer from "store/contentKooClass/contentKooClassSlice";
import loginReducer from "store/login/loginSlice";
import mcqBatchUploadReducer from "store/MCQBatchUpload/MCQBatchUploadSlice";
import skillDifficultyReducer from "store/skillDifficulty/skillDifficultySlice";
import fitbBatchUploadReducer from "store/fitbBatchUpload/fitbBatchUploadSlice";

const middleware = [...getDefaultMiddleware()];

const combinedReducer = combineReducers({
  login: loginReducer,
  question: questionReducer,
  contentOperation: contentReducer,
  contentKooClass: contentKooClassReducer,
  batchUpload: batchUploadReducer,
  mcqBatchUpload: mcqBatchUploadReducer,
  skillDifficulty: skillDifficultyReducer,
  fitbBatchUpload: fitbBatchUploadReducer,
  // APPEND STORE REDUCERS HERE
  status: statusReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "reset-store") {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

export default store;
