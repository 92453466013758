import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import Login from "pages/Login/Login";
import { lazy } from "react";
import { RouteRequiresLogin } from "components/Shared";
import Layout from "components/Layout";
const SearchResult = lazy(() => import("./pages/SearchResult"));
const Search = lazy(() => import("./pages/Search"));
const BatchUpload = lazy(() => import("./pages/BatchUpload"));
const MCQBatchUpload = lazy(() => import("./pages/MCQBatchUpload"));
const ContentOperations = lazy(() => import("./pages/ContentOperations"));
const Tagging = lazy(() => import("./pages/Tagging"));
const FITBBatchUpload = lazy(() => import("./pages/FITBBatchUpload"));
const Status = lazy(() => import("./pages/Status"));
export const history = createBrowserHistory({
  basename: "/",
});

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Layout>
          <RouteRequiresLogin exact path="/search" component={SearchResult} />
          {/* <RouteRequiresLogin exact path="/summary" component={Summary} /> */}
          <RouteRequiresLogin
            exact
            path="/batch-upload"
            component={BatchUpload}
          />
          <RouteRequiresLogin
            exact
            path="/mcq-batch-upload"
            component={MCQBatchUpload}
          />
          <RouteRequiresLogin path="/content" component={ContentOperations} />
          <RouteRequiresLogin path="/tagging" component={Tagging} />
          <RouteRequiresLogin path="/status" component={Status} />
          <RouteRequiresLogin
            exact
            path="/fitb-batch-upload"
            component={FITBBatchUpload}
          />
          <RouteRequiresLogin exact path="/" component={Search} />
        </Layout>
      </Switch>
    </Router>
  );
};

export default Routes;
