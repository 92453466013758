import handleResponse from "helpers/handleAPIResponse";

const baseUrl = process.env.REACT_APP_KOOCLASS_URL;

export const fetchCourses = () => {
  return fetch(`${baseUrl}/config/course/GetAll`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get courses failed");
    });
};

export const fetchTopicsByCourseId = ({ courseId }) => {
  return fetch(`${baseUrl}/config/topiccourse/GetByCourseID/${courseId}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get topics by course id failed");
    });
};

export const patchTopicOrders = (data) => {
  return fetch(`${baseUrl}/config/topiccourse/BulkUpdateDisplayOrder`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      "content-type": "application/json",
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Update topics order failed");
    });
};

export const fetchLessonsByTopicId = ({ topicId }) => {
  return fetch(`${baseUrl}/config/lesson/GetByTopicID/${topicId}`, {
    method: "GET",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get lessons by topic id failed");
    });
};

export const patchLessonOrders = (data) => {
  return fetch(`${baseUrl}/config/lesson/BulkUpdateDisplayOrder`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      "content-type": "application/json",
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Update lessons order failed");
    });
};

export const patchMoveLessonToTopic = ({ lessonId, topicId }) => {
  return fetch(`${baseUrl}/config/lesson/${lessonId}/MoveToTopic/${topicId}`, {
    method: "PATCH",
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      "content-type": "application/json",
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.isSuccessful) {
        throw new Error(result.message.message);
      } else {
        return result.result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Update move lesson to topic failed");
    });
};
