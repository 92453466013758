import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWrapper } from "services/login";
import { fetchDownloadSkillDifficultyTemplate } from "services/download";
import { postSkillDifficultyTaggingUpload } from "services/upload";
export const downloadSkillDifficultyTaggingTemplate = createAsyncThunk(
  "skillDifficultyTagging/downloadSkillDifficultyTaggingTemplate",
  async () => {
    try {
      const res = await fetchWrapper(fetchDownloadSkillDifficultyTemplate);
      return res;
    } catch (error) {
      throw new Error(
        error?.message || "Download skill difficulty tagging template failed"
      );
    }
  }
);
export const uploadSkillDifficultyTagging = createAsyncThunk(
  "skillDifficultyTagging/postSkillDifficultyTaggingUpload",
  async data => {
    try {
      const res = await fetchWrapper(postSkillDifficultyTaggingUpload, data);
      return res;
    } catch (error) {
      throw new Error(
        error?.message || "Skill difficulty tagging upload failed"
      );
    }
  }
);
const initialState = {
  download: {
    isLoading: false,
    error: null,
  },
  upload: {
    isLoading: false,
    error: null,
  },
};
const skillDifficultyTaggingSlice = createSlice({
  name: "skillDifficultyTaggingSlice",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: {
    [downloadSkillDifficultyTaggingTemplate.pending]: state => {
      state.download.isLoading = true;
      state.download.error = null;
    },
    [downloadSkillDifficultyTaggingTemplate.fulfilled]: state => {
      state.download.isLoading = false;
    },
    [downloadSkillDifficultyTaggingTemplate.rejected]: (state, action) => {
      state.download.isLoading = false;
      state.download.error = action.error.message;
    },
    [uploadSkillDifficultyTagging.pending]: state => {
      state.upload.isLoading = true;
      state.upload.error = null;
    },
    [uploadSkillDifficultyTagging.fulfilled]: state => {
      state.upload.isLoading = false;
    },
    [uploadSkillDifficultyTagging.rejected]: (state, action) => {
      state.upload.isLoading = false;
      state.upload.error = action.error.message;
    },
  },
});
export const { resetState } = skillDifficultyTaggingSlice.actions;

const { reducer } = skillDifficultyTaggingSlice;
export default reducer;
